<script lang="ts">
  import * as ShopifyThemeCurrency from "@shopify/theme-currency"
  import { fade } from "svelte/transition"
  import { subTotalCartValue } from "../stores"
  import RewardProgressBar from "./reward-progress-bar.svelte"
  export let targetValue: number
  export let rewardName: string
  let awayValueDisplay: string
  let targetValueDisplay: string

  $: currentValue = ($subTotalCartValue * 100) / targetValue

  $: awayValue = targetValue - $subTotalCartValue

  $: {
    if (currentValue >= 100) awayValue = 0
    awayValueDisplay = ShopifyThemeCurrency.formatMoney(awayValue)

    targetValueDisplay = ShopifyThemeCurrency.formatMoney(targetValue)
  }
</script>

<div class="reward-item">
  {#if currentValue === 0}
    <div class="reward-text">
      Spend <span class="target-value">{targetValueDisplay}</span>
      to get
      <span class="reward-name">{rewardName}</span>
    </div>
    <div class="reward-content-progress-wrapper">
      <RewardProgressBar value={currentValue} />
    </div>
  {:else if awayValue === 0}
    <div class="unlocked-text" transition:fade>
      🎉 {rewardName} unlocked!
    </div>
  {:else}
    <div class="reward-text" transition:fade>
      <span class="target-value">{awayValueDisplay}</span>
      away from
      <span class="reward-name"> {rewardName}</span>
    </div>
    <div class="reward-content-progress-wrapper">
      <RewardProgressBar value={currentValue} />
    </div>
  {/if}
</div>

<!-- <button
  on:click={() => {
    rewardValue += 5
  }}>Click</button
> -->
<style lang="scss">
  .reward-item {
    // display
    display: grid;
    gap: 1rem;
    padding: 1rem;

    // box
    // margin: 1rem 0;
    border-radius: 5px;

    // colors
    background-color: white;
    border: 1px solid rgb(133, 133, 133, 0.3);
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 0.375rem 0px;
  }

  .target-value {
    display: inline-block;
    font-weight: 500;
  }

  .reward-text {
    display: block;
    font-size: small;
  }

  .reward-name {
    display: inline-block;
    font-weight: 600;
    color: #047857;
  }

  .unlocked-text {
    font-size: small;
    color: #047857;
    font-weight: 600;
  }

  .reward-content-progress-wrapper {
    width: 100%;
  }
</style>
