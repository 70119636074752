<script>
  import Label from "./label.svelte"
</script>

<Label label="Zip code" />
<input
  type="text"
  name="zip"
  id="shipping-estimator-zip"
  value=""
  required=""
/>

<style lang="scss">
  #shipping-estimator-zip {
    width: 100%;
    margin-bottom: 2rem;
  }
</style>
