<script lang="ts">
  import CartContentColumn from "./cart-body/cart-content-column.svelte"
  import CartCtaMobile from "./cart-body/cart-cta-mobile.svelte"
  import CartOffersColumn from "./cart-body/cart-offers-column.svelte"
  import CartTitle from "./cart-header/cart-title.svelte"
  import CloseWindow from "./cart-header/close-window.svelte"
  import KeepShopping from "./cart-header/keep-shopping.svelte"
  import FetchCart from "./fetch-cart.svelte"
</script>

<main id="container">
  <div id="cart-header">
    <!-- <SaveForLaterBtn /> -->
    <CartTitle />
    <CloseWindow />
  </div>

  <div id="cart-body">
    <CartContentColumn />
    <CartOffersColumn />
  </div>

  <div id="cart-cta-mobile-wrapper">
    <CartCtaMobile />
  </div>
</main>
<FetchCart />

<style lang="scss">
  #container {
    height: 100%;
    display: grid;
    grid-template-rows: 8% auto;
    overflow: auto;
  }

  #cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  #cart-body {
    overflow: auto;
    display: grid;
    grid-template-columns: 48% 52%;
  }

  #cart-cta-mobile-wrapper {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    #cart-body {
      display: block;
    }

    #container {
      width: 100%;
      height: 100vh;
      display: grid;
      grid-template-rows: 8% 1fr auto;
    }

    #cart-cta-mobile-wrapper {
      display: block;
    }
  }
</style>
