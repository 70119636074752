<div class="modal-wrapper">
  <slot />
</div>

<style lang="scss">
  .modal-wrapper {
    min-width: 10%;
    min-height: 10%;
    padding: 2rem;
    border-radius: 5px;
    background-color: white;
  }
</style>
