<script>
  export let text
</script>

<div class="section-title">{text}</div>

<style>
  .section-title {
    font-size: medium;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    .section-title {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
</style>
