<script>
  import AddPromoCode from "../calculate-cost/add-promo-code.svelte"
  import CartItem from "../cart-item/cart-item.svelte"
  import CountDownTimer from "../cart-untils/count-down-timer.svelte"
  import OrderInstructions from "../cart-untils/order-instructions.svelte"
  import ShippingAddressResult from "../estimate-shipping/shipping-address-result.svelte"
  import AddedToCartStatus from "../lib/added-to-cart-status.svelte"
  import DetailsDisclosure from "../lib/details-disclosure.svelte"
  import Divider from "../lib/divider.svelte"
  import { itemsInCart } from "../stores"
</script>

<div class="cart-items">
  <!-- <CartNotifications /> -->
  <div class="count-down-timer-wrapper" style="margin-bottom: 1rem">
    <CountDownTimer isDisplay={true} minutesCountDown={10} />
  </div>
  <AddedToCartStatus />

  {#each $itemsInCart as product}
    <CartItem {...product} />
  {/each}

  <DetailsDisclosure disclosureName="Promo code">
    <AddPromoCode />
  </DetailsDisclosure>

  <Divider />

  <DetailsDisclosure disclosureName="Order instructions">
    <OrderInstructions />
  </DetailsDisclosure>

  <Divider />

  <DetailsDisclosure disclosureName="Estimate shipping">
    <ShippingAddressResult />
  </DetailsDisclosure>
</div>

<style lang="scss">
  .cart-items {
    overflow: auto;
    padding: 1rem 1.5rem 4rem 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    .count-down-timer-wrapper {
      display: none;
    }
  }
</style>
