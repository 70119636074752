<script lang="ts">
  export let label: string = ""
</script>

<div>{label}</div>

<style>
  div {
    font-size: small;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
</style>
