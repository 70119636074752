<script lang="ts">
  import DotsLoader from "../lib/dots-loader.svelte"
  import { loadingToCheckout } from "../stores"

  function goToCheckout() {
    loadingToCheckout.set(true)

    setTimeout(() => {
      loadingToCheckout.set(false)
    }, 90000)
  }
</script>

<button class="go-to-checkout-btn" on:click={goToCheckout}>
  {#if $loadingToCheckout}
    <div class="btn-content-container">
      <DotsLoader />
    </div>
  {:else}
    <div class="btn-content-container">Checkout</div>
  {/if}
</button>

<style lang="scss">
  .go-to-checkout-btn {
    width: 100%;
    background-color: black;
    font-weight: 500;
    color: white;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
    font-size: large;
    margin: 1rem 0 1rem 0;
    height: 50px;
  }

  .go-to-checkout-btn:hover {
    background-color: rgba($color: #000000, $alpha: 0.8);
  }

  .btn-content-container {
    display: grid;
    place-items: center;
  }
</style>
