<script lang="ts">
  export let variantTitle: string
</script>

<div class="item-variant-title">{variantTitle}</div>

<style>
  .item-variant-title {
    font-size: x-small;
  }
</style>
