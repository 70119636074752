<script lang="ts">
  export let disclosureName: string = ""
</script>

<details style="padding: 1rem 0;">
  <summary><span class="disclosure-name">{disclosureName}</span></summary>
  <slot />
</details>

<style lang="scss">
  details {
    display: block;
    font-size: small;
  }

  .disclosure-name {
    margin-left: 6px;
  }

  summary {
    font-size: small;
    cursor: pointer;
  }

  details[open] {
    padding: 0;
  }

  details[open] summary {
    margin-bottom: 0.5em;
  }
</style>
