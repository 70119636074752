<script>
  import TotalCartCost from "../calculate-cost/total-cart-cost.svelte"
  import ContinueShoppingBtn from "../cart-cta/continue-shopping-btn.svelte"
  import GoToCheckoutButton from "../cart-cta/go-to-checkout-button.svelte"
  import SavingCost from "../calculate-cost/saving-cost.svelte"
  import ShippingCost from "../calculate-cost/shipping-cost.svelte"
  import SubtotalCost from "../calculate-cost/subtotal-cost.svelte"
  import Divider from "../lib/divider.svelte"
</script>

<div class="cart-cta">
  <SubtotalCost />
  <ShippingCost />
  <SavingCost />
  <div style="padding-top: 1rem;" />
  <Divider />
  <div style="padding-bottom: 1rem;" />
  <div class="cart-total-amount">
    <div>Total</div>
    <TotalCartCost />
  </div>
  <GoToCheckoutButton />
  <ContinueShoppingBtn />
</div>

<style lang="scss">
  .cart-cta {
    display: block;
    text-align: center;
    padding: 1.5rem;
    box-shadow: rgb(0 0 0 / 8%) 0px -0.125rem 0.25rem;
  }

  .cart-total-amount {
    display: flex;
    justify-content: space-between;
    font-size: larger;
  }

  @media only screen and (max-width: 768px) {
    .cart-cta {
      display: none;
    }
  }
</style>
