<script lang="ts">
  import * as ShopifyThemeCurrency from '@shopify/theme-currency'
  import { subTotalCartValue, itemsInCart } from '../stores'

  $: {
    $subTotalCartValue = $itemsInCart.reduce(
      (startValue, currentItem) =>
        startValue +
        Number.parseFloat(currentItem.originPrice) * currentItem.quantity,
      0
    )
  }
</script>

<div class="cost-calculate">
  <div class="cost-text">Subtotal</div>
  <div class="cost-value">
    {ShopifyThemeCurrency.formatMoney($subTotalCartValue, null)}
  </div>
</div>

<style>
  .cost-calculate {
    display: flex;
    justify-content: space-between;
    font-size: smaller;
    padding: 0.3rem 0;
  }

  .cost-value {
    font-weight: 600;
  }
</style>
