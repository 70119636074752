<script lang="ts">
  import ItemPrices from "../cart-item/item-prices.svelte"
  import AddUpsellItemToCartBtn from "../upsell-item/add-upsell-item-to-cart-btn.svelte"
  import UpsellItemTitle from "./upsell-item-title.svelte"

  export let itemId: string
  export let imgUrl: string
  export let imgAlt: string = "product-image-alt"
  export let originPrice: number
  export let discountedPrice: number
  export let itemTitle: string
  export let variantTitle: string
  export let quantity: number = 1
  export let productUrl: string
</script>

<div class="upsell-item-wrapper">
  <div class="upsell-item-image-wrapper">
    <img src={imgUrl} alt={imgAlt} loading="lazy" />
  </div>
  <div class="item-content">
    <UpsellItemTitle {itemTitle} {productUrl} />
    <div class="item-description">{variantTitle}</div>
    <div>
      <ItemPrices {originPrice} {discountedPrice} {quantity} />
    </div>
    <div class="upsell-item-add-to-cart-wrapper">
      <AddUpsellItemToCartBtn thisItemId={itemId} />
    </div>
  </div>
</div>

<style lang="scss">
  .upsell-item-wrapper {
    // display
    display: inline-flex;
    flex-direction: column;
    width: 8.5rem;

    // box
    border-radius: 0 0 5px 5px;
    padding-bottom: 0.5rem;

    // colors
    background-color: white;
    border: 1px solid rgb(133, 133, 133, 0.3);
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 0.375rem 0px;
  }

  .upsell-item-image-wrapper {
    height: 8.5rem;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(238, 238, 238, 0.1);
  }

  .item-content {
    padding: 0.5rem 0.75rem 0.375rem 0.75rem;
    display: flex;
    flex-direction: column;
  }

  // .upsell-item-title {
  //   font-size: small;
  //   cursor: pointer;
  //   font-weight: 600;
  //   padding-bottom: 3px;
  //   // border-bottom: 1px solid white;
  // }

  // .upsell-item-title:hover {
  //   // border-bottom: 1px solid gray;
  //   cursor: pointer;
  //   color: rgba($color: #000000, $alpha: 0.6);
  // }

  .item-description {
    font-size: x-small;
    margin-bottom: 0.375rem;
  }

  .upsell-item-add-to-cart-wrapper {
    display: inline-block;
  }
</style>
