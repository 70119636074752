<script lang="ts">
  export let imgUrl: string
  export let imgAlt: string = 'product-alt'
</script>

<div class="item-image-wrapper">
  <img src={imgUrl} alt={imgAlt} width="100%" height="100%" loading="lazy" />
</div>

<style lang="scss">
  .item-image-wrapper {
    display: grid;
    place-items: center;
    width: 5rem;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
</style>
