<script lang="ts">
  import TagIcon from "../icons/tag-icon.svelte"
  import XIcon from "../icons/x-icon.svelte"
  import DotsLoaderSmall from "../lib/dots-loader-small.svelte"

  let coupon: string = ""

  let checkingCoupon = false

  const removeCode = () => {
    alert("removing code")
  }

  function onCheckingCoupon() {
    const applyCodeBtn: HTMLButtonElement =
      document.querySelector(".apply-code-btn")
    applyCodeBtn.disabled = true
    checkingCoupon = true

    const addCouponMessage = JSON.stringify({
      message: "checking-discount",
      discountCode: coupon,
    })

    window.parent.postMessage(addCouponMessage, "*")
    setTimeout(() => {
      checkingCoupon = false
      applyCodeBtn.disabled = false
    }, 5000)
  }
</script>

<div class="wrapper">
  <div class="apply-coupon">
    <div>
      <input
        class="coupon-input"
        placeholder="Discount code"
        type="text"
        bind:value={coupon}
      />
    </div>
    <div>
      <button class="apply-code-btn" on:click={onCheckingCoupon}>
        {#if checkingCoupon}
          <div class="btn-content-container">
            <DotsLoaderSmall />
          </div>
        {:else}
          <div class="btn-content-container">Apply</div>
        {/if}
      </button>
    </div>
  </div>
  <!-- <div class="invalid-promo-code-message">Invalid code</div> -->
  <div class="applied-promo-code-message">
    <div class="icon-wrapper"><TagIcon height="13px" /></div>
    <div class="promo-code-text">HAPPY</div>
    <div
      class="icon-wrapper remove-code"
      style="cursor: pointer;"
      on:click={removeCode}
    >
      <XIcon height="16px" />
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    padding-top: 1rem;
    font-size: medium;
  }
  .coupon-input {
    background-color: rgb(133, 133, 133, 0.15);
    border: none;
    padding: 10px;
    margin: 0;
    font-size: small;
    width: 100%;
  }

  .apply-coupon {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
  }

  .apply-code-btn {
    width: 84px;
    -webkit-box-pack: center;
    background: black;
    color: white;
    border-radius: 3px;
    height: 100%;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 500;
    font-size: small;
  }

  // .invalid-promo-code-message {
  //   font-size: x-small;
  //   font-weight: 500;
  //   margin: 0.5rem 0;
  //   color: red;
  // }

  .applied-promo-code-message {
    font-size: small;
    background-color: rgba(113, 113, 113, 0.11);

    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    gap: 8px;
    padding: 0.5rem;
    border: 1px solid #717171;
    border-radius: 3px;
    margin: 1rem 0;
    color: #717171;
  }

  .promo-code-text {
    font-weight: 500;
    color: initial;
  }

  .icon-wrapper {
    display: grid;
    place-items: center;
  }

  .btn-content-container {
    display: grid;
    place-items: center;
  }
</style>
