<script lang="ts">
  import { setModalOpen } from "../stores"

  function handleEditAddress() {
    setModalOpen.set(true)
  }
</script>

<div class="wrapper">
  <div class="label">🚚 Est shipping rate</div>
  <ul>
    <li>International Shipping - $20.00</li>
    <li>Dosmetic Shipping - $10.00</li>
  </ul>

  <div class="label">🌍 Address</div>
  <div class="address">Vietnam, 100000</div>
  <div class="edit-btn" on:click={handleEditAddress}>Edit</div>
</div>

<style lang="scss">
  .wrapper {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px dashed rgba(185, 185, 185, 0.8);
    border-radius: 3px;
    background-color: rgba(185, 185, 185, 0.15);
  }

  .address {
    font-size: medium;
    margin: 0.375rem 0 0.5rem 0;
    font-weight: 500;
  }

  .edit-btn {
    cursor: pointer;
    font-size: small;
    font-weight: 500;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.6);
  }

  .label {
    font-size: small;
  }

  ul {
    font-weight: 500;
    font-size: small;
    padding: 0;
    list-style-position: inside;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  li {
    padding: 0.375rem 0;
  }
</style>
