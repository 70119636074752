<script lang="ts">
  import DotsLoaderSmall from "../lib/dots-loader-small.svelte"

  let isEstimatingShipping: boolean = false

  function onEstimatingShippingCost() {
    const button: HTMLButtonElement = document.querySelector(
      ".estimator-shipping-btn"
    )
    button.disabled = true
    isEstimatingShipping = true

    setTimeout(() => {
      isEstimatingShipping = false
      button.disabled = false
    }, 5000)
  }
</script>

<button
  type="button"
  class="estimator-shipping-btn"
  data-action="estimate-shipping"
  on:click={onEstimatingShippingCost}
>
  {#if isEstimatingShipping}
    <div class="btn-content-container">
      <DotsLoaderSmall />
    </div>
  {:else}
    <div class="btn-content-container">Estimate</div>
  {/if}
</button>

<style lang="scss">
  .estimator-shipping-btn {
    width: 84px;
    background: black;
    color: white;
    border-radius: 3px;
    height: 100%;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 500;
    font-size: small;
    height: 36px;
  }

  .btn-content-container {
    display: grid;
    place-items: center;
  }
</style>
