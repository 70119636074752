<script>
  import { itemsInCart, cartLength } from "../stores"

  $: {
    if (!$itemsInCart.length) $cartLength = 0
    $cartLength = $itemsInCart.reduce(
      (startValue, currentItem) => startValue + currentItem.quantity,
      0
    )

    const cartLengthmessage = JSON.stringify({
      cartLength: $cartLength,
    })
    window.parent.postMessage(cartLengthmessage, "*")
  }
</script>

<div class="cart-title">
  Your Cart <span class="cart-length">({$cartLength}) </span>
</div>

<style lang="scss">
  .cart-title {
    font-weight: 600;
    font-size: larger;
    color: #333;
  }

  .cart-length {
    font-weight: 400;
    letter-spacing: 2px;
  }
</style>
