<script lang="ts">
  export let value: number = 0

  $: {
    if (value > 100) value = 100
    if (value < 0) value = 0
  }
</script>

<div class="progress-bar-container">
  <span style="width: {value}%;" />
</div>

<style lang="scss">
  .progress-bar-container {
    background-color: rgb(214, 217, 222);
    border-radius: 20px;
    height: 6px;
    position: relative;
  }

  .progress-bar-container > span {
    display: block;
    height: 100%;
    // border-top-right-radius: 8px;
    // border-bottom-right-radius: 8px;
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
    border-radius: 20px;
    // background-color: rgb(97, 97, 97);
    // background-color: rgb(0, 109, 76);
    background-color: #047857;
    transition: width 0.2s ease-in-out 0s;

    // background-color: black;
    position: relative;
    overflow: hidden;
  }
</style>
