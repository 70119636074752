<script>
  import XIcon from "../icons/x-icon.svelte"

  const handleClick = () => {
    const message = JSON.stringify({
      message: "close-iframe",
    })
    window.parent.postMessage(message, "*")
  }
</script>

<div class="back-to-shopping-btn" on:click={handleClick}>
  <XIcon height="50%" />
</div>

<style lang="scss">
  .back-to-shopping-btn {
    display: grid;
    place-items: center;
    justify-items: end;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    color: #888888;
  }
</style>
