<script lang="ts">
  import { provinceList, provinceSelect } from "../stores"
  import Label from "./label.svelte"

  let provincesJSON = []

  $: {
    if ($provinceList) {
      provincesJSON = JSON.parse($provinceList)

      const provinceElement: HTMLSelectElement = document.querySelector(
        "#shipping-estimator-province"
      )

      if (provinceElement) {
        provinceElement.addEventListener("change", () => {
          provinceSelect.set(provinceElement.value)
        })
      }
    }
  }
</script>

{#if provincesJSON.length > 0}
  <Label label="Province" />
  <select name="province" id="shipping-estimator-province" data-default="">
    {#each provincesJSON as province}
      <option value={province[0]}>{province[1]}</option>
    {/each}
  </select>
{/if}

<style lang="scss">
  select {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
</style>
