<script>
  import CartCtaMobile from "./cart-cta-mobile.svelte"
  import CartCtaSection from "./cart-cta-section.svelte"
  import CartItemsSection from "./cart-items-section.svelte"
  import CartOffersMobile from "./cart-offers-mobile.svelte"
  import CartItemsEmpty from "./cart-items-empty.svelte"

  import { itemsInCart } from "../stores"
</script>

<div id="cart-content">
  {#if $itemsInCart.length === 0}
    <div style="padding: 4rem 0;">
      <CartItemsEmpty />
    </div>
  {:else}
    <CartItemsSection />
    <CartCtaSection />
  {/if}
</div>
<CartOffersMobile />

<style>
  #cart-content {
    min-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  @media only screen and (max-width: 768px) {
    #cart-content {
      min-height: 60%;
    }
  }
</style>
