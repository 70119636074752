<script lang="ts">
  console.log("zzz")
</script>

<div class="wrapper">
  <div>
    <textarea class="message-input" placeholder="Enter your message" />
  </div>
</div>

<style lang="scss">
  .wrapper {
    padding-top: 0.5rem;
    font-size: medium;
  }

  .message-input {
    background-color: rgb(133, 133, 133, 0.15);
    border: none;
    padding: 10px;
    margin: 0;
    font-size: small;
    width: 100%;
    resize: vertical;
    min-height: 10vh;
    margin-bottom: 1rem;
  }
</style>
