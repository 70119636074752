<script lang="ts">
  import CartContainer from "./cart-container.svelte"
  import CountryList from "./estimate-shipping/country-list.svelte"
  import EstimateShippingButton from "./estimate-shipping/estimate-shipping-button.svelte"
  import EstimateShipping from "./estimate-shipping/estimate-shipping.svelte"
  import ProvinceList from "./estimate-shipping/province-list.svelte"
  import ModalWrapper from "./lib/modal/modal-wrapper.svelte"
  import { setModalOpen } from "./stores"

  export let inIframe: boolean
</script>

<svelte:head>
  <title>Smooth Cart</title>
</svelte:head>

<!-- {#if inIframe}
  <CartContainer />
{:else}
  <div>Ooooopssss... Good luck! 🙂</div>
{/if} -->

<CartContainer />

<div
  class="full-screen-overlay"
  style={`display: ${$setModalOpen ? "grid" : "none"} `}
>
  <ModalWrapper>
    <EstimateShipping />
  </ModalWrapper>
</div>

<style lang="scss">
  div {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
  }

  .full-screen-overlay {
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    place-items: center;
    overflow: none;
  }
</style>
