<script lang="ts">
  import { loadingToCheckout } from "../stores"

  const handleClick = () => {
    const message = JSON.stringify({
      message: "close-iframe",
    })
    window.parent.postMessage(message, "*")
  }
</script>

<div class="continue-shopping-container">
  {#if $loadingToCheckout}
    Processing To Checkout
  {:else}
    <div class="continue-shopping" on:click={handleClick}>
      Continue Shopping
    </div>
  {/if}
</div>

<style lang="scss">
  .continue-shopping-container {
    font-size: small;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
  }

  .continue-shopping {
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px solid white;
  }

  .continue-shopping:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  }
</style>
