<script lang="ts">
  import RewardItem from "../cart-reward/reward-item.svelte"
  import recommendProducts, { myRewards } from "../fake-data"
  import SectionTitle from "../lib/section-title.svelte"
  import { itemsInCart, itemsUpsell } from "../stores"
  import UpsellItem from "../upsell-item/upsell-item.svelte"

  itemsUpsell.set(recommendProducts)

  let upsellTitle: string = ""

  $: {
    $itemsInCart.length > 0
      ? (upsellTitle = "Frequently purchased together")
      : (upsellTitle = "Toss these products in")
  }
</script>

<!-- cart offers column -->
<div class="cart-offers">
  <div class="cart-recommendations">
    <SectionTitle text={upsellTitle} />

    <div class="upsell-items-container">
      {#each $itemsUpsell as product}
        <UpsellItem {...product} />
      {/each}
    </div>
  </div>

  <div class="cart-rewards">
    <SectionTitle text="Rewards as you spend" />

    <div class="reward-items-container">
      {#each myRewards as reward}
        <RewardItem targetValue={reward.targetValue} rewardName={reward.name} />
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .cart-offers {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1.5rem 2rem;
    overflow-y: auto;
  }

  .upsell-items-container {
    padding: 0 1rem 1.5rem 0;
    display: grid;
    grid-auto-flow: column dense;
    gap: 1rem;
    overflow-x: scroll;
  }

  .cart-rewards {
    margin-top: 2rem;
  }

  .reward-items-container {
    overflow-x: auto;
    display: grid;
    gap: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .cart-offers {
      display: none;
    }
  }
</style>
